import React, { useContext } from 'react'
import { AppStateContext } from '../../contexts/AppStateContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SwitchBusiness = () => {
  const { salonsState, salonState } = useContext(AppStateContext);
  const [salons,] = salonsState;
  const [, setSalon,] = salonState;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSalonChange = (salon) => {
    try {
      setSalon(salon)
      navigate('/admin/dashboard')
      toast.success(`Salon changed to ${salon.name}`)
    } catch (error) {
      toast.error('Error changing salon')
    }
  }

  return (
    <>
      <div className="overflow-x-auto max-h-[calc(100vh-9rem)] pb-12 overflow-y-auto">
        <table className="table table-pin-rows bg-base-100/60">
          <thead>
            <tr>
              <th></th>
              <th className="min-w-12">{t('salons.details.name')}</th>
              <th>{t('salons.details.email')}</th>
              <th>{t('salons.details.phone_number')}</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.values(salons)?.length > 0 && Object.values(salons).map((salon) => {
                return (
                  <tr key={salon.id}>
                    <td>
                      <div className="flex gap-2">
                        <button className="btn btn-outline border-none" onClick={() => { handleSalonChange(salon) }}>
                          <FontAwesomeIcon icon="fa-solid fa-rotate" />
                        </button>
                      </div>
                    </td>
                    <td>{ salon.name }</td>
                    <td>{ salon.email }</td>
                    <td>{ salon.phone_number }</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default SwitchBusiness