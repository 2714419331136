import moment from 'moment';
import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const IntakeContext = createContext();

export const IntakeProvider = ({ children }) => {
  const [salon, setSalon] = useState({})
  const defaultAppointment = {
    start_date: '',
    end_date: '',
    employee: {},
    customer: {
      first_name: '',
      email: '',
      phone_number: '',
    },
    salon_products: [],
    payment_method: null
  }
  const [appointment, setAppointment] = useState(defaultAppointment)
  const [capacity, setCapacity] = useState({})
  const [fetchingCapacity, setFetchingCapacity] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [freeSlots, setFreeSlots] = useState([])
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState(null);
  const [maxDuration, setMaxDuration] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [eligibleEmployees, setEligibleEmployees] = useState([])
  const [confirming, setConfirming] = useState(false);
  const [validPaymentMethods, setValidPaymentMethods] = useState([]);
  const [acceptedPrivacyAndTerms, setAcceptedPrivacyAndTerms] = useState(false);
  const [prepaymentSelected, setPrepaymentSelected] = useState(false)
  const [locale, setLocale] = useState({})
  const [showBusinessInfo, setShowBusinessInfo] = useState(true);
  const { salonUUID, appointmentUUID } = useParams();
  const location = useLocation();
  const mixedSalonUUID = location.pathname === "/conociendonos" ? `${process.env.REACT_APP_NEW_BUSINESS_UUID}` : salonUUID;

  const capacityRef = useRef(capacity);
  const salonRef = useRef(salon);
  const appointmentRef = useRef(appointment)
  const selectedProductsRef = useRef(selectedProducts);
  const selectedDateRef = useRef(selectedDate);
  const currentMonthConfigRef = useRef(null);
  const nextMonthConfigRef = useRef(null);
  const previousMonthConfigRef = useRef(null);

  const fetchSalon = useCallback(async () => {
    fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salon_info/${mixedSalonUUID}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => response.json())
    .then(data => {
      if (!data.errors) {
        setSalon(data)
        setLocale(data.locale)
      } else {
        toast.error(data.errors.join(','))
      }
    })
    .catch(error => {
      console.error('Error fetching data: ', error);
    });
  },[mixedSalonUUID])

  const fetchAppointment = useCallback(async () => {
    let url = new URL(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/reservations/${appointmentUUID}`)

    fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => response.json())
      .then(data => {
        if (!data.errors) {
          setSalon(data.salon)
          setLocale(data.salon.locale)
          setAppointment(data.appointment)
          const salonProducts = data.appointment.salon_products
          setMaxDuration(
            salonProducts.reduce((total, product) => {
              return total + product.duration;
            }, 0)
          )
          setTotalPrice(
            salonProducts.reduce((total, product) => {
              return total + product.price_cents;
            }, 0) / 100.0
          )
        } else {
          toast.error(data.errors.join(','))
        }
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  },[appointmentUUID])

  const fetchCapacity = useCallback(async (direction) => {
    if (fetchingCapacity) return

    const previousMonthConfig = previousMonthConfigRef.current
    const monthConfig = currentMonthConfigRef.current
    const nextMonthConfig = nextMonthConfigRef.current
    const salonProducts = appointmentRef.current.salon_products
    const selectedEmployee = appointmentRef.current.employee

    const queryParamsObj = {
      date: selectedDateRef.current,
      salon_product_ids: salonProducts.map((product) => product.id),
    };
    if (selectedEmployee?.id) {
      queryParamsObj.employee_id = selectedEmployee.id
    }
    const queryParams = new URLSearchParams(queryParamsObj)

    const url = `${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/salon_capacity/${salonRef.current.uuid}?${queryParams}`
    const isBackwardsAndExists = direction === -1 && capacityRef.current[`${previousMonthConfig}`]
    const isCurrentAndExists = direction === 0 && capacityRef.current[`${monthConfig}`]
    const isForwardsAndExists = direction === 1 && capacityRef.current[`${nextMonthConfig}`]

    if (isBackwardsAndExists || isForwardsAndExists || isCurrentAndExists) return

    setFetchingCapacity(true)
    fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
      .then(response => response.json())
      .then(data => {
        if (!data.errors) {
          setCapacity((prevCapacity) => {
            return {
              ...prevCapacity,
              [`${previousMonthConfig}`]: data[0],
              [`${monthConfig}`]: data[1],
              [`${nextMonthConfig}`]: data[2]
            }
          })
          setFetchingCapacity(false)
        } else {
          toast.error(data.errors.join(','))
        }
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
        toast.error('Failed to fetch capacity data');
      });
  }, [fetchingCapacity])

  useEffect(() => {
    mixedSalonUUID && fetchSalon();
    appointmentUUID && fetchAppointment();
  }, [mixedSalonUUID, appointmentUUID, fetchSalon, fetchAppointment])

  useEffect(() => {
    capacityRef.current = capacity;
  }, [capacity]);

  useEffect(() => {
    salonRef.current = salon;
  }, [salon]);

  useEffect(() => {
    appointmentRef.current = appointment
  }, [appointment])

  const monthConfigID = (date, products, employeeId) => {
    const queryParamsObj = {
      date: `${moment(date).format('M-YYYY')}`,
      salon_product_ids: products.map((product) => product.id)
    };
    if (employeeId) {
      queryParamsObj.employee_id = employeeId
    }
    return new URLSearchParams(queryParamsObj);
  }

  const nextMonth = (date) => {
    return moment(date).add(1, 'months').toDate(); // Add 1 month and convert back to native Date object
  }

  const previousMonth = (date) => {
    return moment(date).subtract(1, 'months').toDate(); // Subtract 1 month and convert back to native Date object
  }

  const updateMonthConfig = useCallback((date, products, employeeId) => {
    currentMonthConfigRef.current = monthConfigID(date, products, employeeId);
    nextMonthConfigRef.current = monthConfigID(nextMonth(date), products, employeeId);
    previousMonthConfigRef.current = monthConfigID(previousMonth(date), products, employeeId);
  }, []);

  useEffect(() => {
    updateMonthConfig(
      selectedDate,
      appointmentRef.current.salon_products,
      appointmentRef.current.employee?.id
    )
    const previousValue = selectedDateRef.current
    selectedDateRef.current = selectedDate;
    if (appointmentRef.current.salon_products.length > 0) {
      fetchCapacity(selectedDateRef.current > previousValue ? 1 : -1);
    }
  }, [selectedDate, fetchCapacity, updateMonthConfig])

  useEffect(() => {
    updateMonthConfig(
      selectedDateRef.current,
      appointment.salon_products,
      appointmentRef.current.employee?.id
    )
    if (appointment.salon_products.length > 0) {
      fetchCapacity(0);
    }
  }, [appointment.salon_products, fetchCapacity, updateMonthConfig])

  useEffect(() => {
    updateMonthConfig(
      selectedDateRef.current,
      appointmentRef.current.salon_products,
      appointment.employee?.id
    )
    if (appointment.employee?.id) {
      fetchCapacity(0);
    }
  }, [appointment.employee?.id, fetchCapacity, updateMonthConfig])

  useEffect(() => {
    const currentMonthConfig = monthConfigID(selectedDate, appointment.salon_products, appointment.employee?.id)
    if (capacity?.[`${currentMonthConfig}`]) {
      setFreeSlots(capacity?.[`${currentMonthConfig}`]?.days?.[selectedDate?.getDate()] || [])
    }
  }, [capacity, selectedDate, appointment.salon_products, appointment.employee?.id])

  return (
    <IntakeContext.Provider
      value={{
        mixedSalonUUID, appointmentUUID,
        locale, setLocale,
        salon, setSalon,
        appointment, setAppointment,
        confirming, setConfirming,
        showBusinessInfo, setShowBusinessInfo,
        capacity, setCapacity, capacityRef, fetchCapacity,
        selectedProducts, setSelectedProducts, selectedProductsRef,
        selectedDate, setSelectedDate, selectedDateRef,
        selectedSlot, setSelectedSlot, freeSlots,
        firstName, setFirstName,
        phoneNumber, setPhoneNumber,
        email, setEmail,
        maxDuration, setMaxDuration,
        totalPrice, setTotalPrice,
        eligibleEmployees, setEligibleEmployees,
        validPaymentMethods, setValidPaymentMethods,
        prepaymentSelected, setPrepaymentSelected,
        acceptedPrivacyAndTerms, setAcceptedPrivacyAndTerms,
        currentMonthConfigRef
      }}
    >
      {children}
    </IntakeContext.Provider>
  );
};