import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { formatValue } from 'react-currency-input-field';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Pricing = () => {
  const { t } = useTranslation();
  const pricesData = t('pricing', { returnObjects: true })

  return (
    <div className="grid grid-rows-12 grid-flow-col gap-x-1 m-auto">
      <div className="grid grid-rows-subgrid row-span-10 row-start-2">
        {
          Object.entries(pricesData.row_headers).map(([key, label], index) => {
            return <div key={key} className={`min-w-[180px] max-h-[40px] font-bold p-2 text-center ${index > 2 && 'border-t border-indigo-100'}`}>{label}</div>
          })
        }
      </div>
      {
        pricesData.tiers.map((tier, tierIndex) => {
          return <div className={`grid grid-rows-subgrid row-span-12 ${tierIndex === 1 && 'border-2 border-indigo-400 text-black rounded-xl bg-indigo-200'} `} key={tierIndex}>
            <div></div>
            {
              Object.entries(tier).map(([key, {type, value, title}], index) => {
                return <div className={`min-w-[220px] max-h-[60px] lg:max-h-[45px] py-2 px-4 text-center ${index > 2 && 'border-t border-indigo-100'}`} key={`${tierIndex}-${key}`}>
                  {
                    type === 'boolean' ? <>
                      { value ? <FontAwesomeIcon icon="fa-solid fa-check" className='text-success'/> : '-' }
                    </> : type === 'string' ? <div>
                      { value }
                    </div> : type === 'button' ? <>
                      <Link to={'/admin/contact'} className='btn btn-primary btn-sm px-10 rounded-full'>{ value }</Link>
                    </> : type === 'currency' ? <div className='flex justify-center gap-2'>
                      <div className='text-3xl'>
                        {formatValue({ value: String(value), intlConfig: { locale: 'es-ES', currency: 'EUR' } })}
                      </div>
                      <div className='self-end'>
                        / mensual
                      </div>
                    </div> : type === 'icons' ? <div className='h-full flex justify-center gap-4 items-center' title={title}>
                      { value.split(',').map(icon => <FontAwesomeIcon icon={icon} size='xl' className={icon === 'fab fa-whatsapp' ? 'text-success' : ''}/>) }
                    </div> : null
                  }
                </div>
              })
            }
            <div></div>
          </div>
        })
      }
    </div>
  )
}

export default Pricing