import React, { useContext, useEffect, useState } from 'react';
import { AppStateContext } from '../../contexts/AppStateContext';
import PhoneInput from 'react-phone-number-input';
import Loading from '../../components/utils/loading';
import { deleteSlot, updateSalon } from '../../lib/api_service/salons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import SalonSlotForm from '../../components/salon/slots/form';
import { fetchPaymentMethods } from '../../lib/api_service/payment_methods';
import { useTranslation } from 'react-i18next';

const defaultFormSalon = {
  name: '',
  email: '',
  google_url: '',
  website: '',
  address: '',
  slug: '',
  slot_interval: '',
  online_payments: false,
  phone_number: '',
  reschedule_deadline: 0,
  payment_methods: [],
  multi_products: false
};

const Configuration = () => {
  const { t } = useTranslation();
  const { salonState, localeState, formSlotState, salonFormState } = useContext(AppStateContext);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [locale,] = localeState;
  const [salon, setSalon,] = salonState;
  const [formSalon, setFormSalon] = useState(defaultFormSalon);
  const [formSlot, setFormSlot] = formSlotState;
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [paymentsVisible, setPaymentsVisible] = useState(false);
  const [,,saveSalon] = salonFormState;
  const weekDays = t('dates.weekDays.long', { returnObjects: true });

  const handleSlotForm = (weekDay, weekDayLabel) => {
    setFormSlot({
      ...formSlot,
      week_day: weekDay,
      week_day_label: weekDayLabel
    });
    document.getElementById('salon-slot-form').showModal();
  };

  useEffect(() => {
    if (salon?.id) {
      setFormSalon({...salon});
    }
  }, [salon]);

  useEffect(() => {
    fetchPaymentMethods()
      .then(response => response.json())
      .then((data) => {
        setPaymentMethods(data);
      });
  },[]);

  const slugRegex = /^[a-z0-9]+(-[a-z0-9]+)*$|^$/;

  const handleFormSubmit = () => {
    if (formSalon.slug !== '' && (formSalon.slug?.length < 3 || formSalon.slug?.length > 50)) {
      toast.error(t('configurations.slug.invalid_length'));
      return
    } else if (!slugRegex.test(formSalon.slug)) {
      toast.error(t('configurations.slug.invalid_characters'));
      return
    }

    updateSalon(formSalon)
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          toast.error(data.errors.join(', '));
        } else {
          saveSalon(data);
          toast.success(t('configurations.salon_updated'));
        }
      });
  };

  const handleDestroySlot = (slot) => {
    const userConfirmed = window.confirm(t('configurations.confirm_delete_slot'));
    if (!userConfirmed) return;

    deleteSlot(salon, slot)
      .then(response => response.json())
      .then((data) => {
        if (data.errors) {
          toast.error(`${t('configurations.error_deleting_slot')} ${data.errors}`);
        } else {
          setSalon(data);
          saveSalon(data);
          toast.success(t('configurations.slot_deleted'));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeadlineChange = (e) => {
    let newValue = e.target.value.replace(/[^0-9]/g, '');
    if (newValue.length > 1 && newValue.startsWith('0')) {
      newValue = '0'
    }

    setFormSalon(
      {
        ...formSalon,
        reschedule_deadline: newValue
      }
    );
  }

  const handleFormChange = (e) => {
    setFormSalon(
      {
        ...formSalon,
        [e.target.name]: e.target.value
      }
    );
  };

  const handleCurrencyChange = (value, name) => {
    setFormSalon({
      ...formSalon,
      [name]: value
    });
  };

  const addPaymentMethod = () => {
    if (!selectedPaymentMethod) return;

    addToForm();
    removeFromAvailable();
    setSelectedPaymentMethod(null);
  };

  const removePaymentMethod = () => {
    if (!selectedPaymentMethod) return;

    addToAvailable();
    removeFromForm();
    setSelectedPaymentMethod(null);
  };

  const addToAvailable = () => {
    const paymentMethodExists = paymentMethods?.some(pm => pm.id === selectedPaymentMethod.id);
    if (paymentMethodExists) return;

    const newPaymentMethods = [
      ...paymentMethods,
      selectedPaymentMethod
    ];
    setPaymentMethods(newPaymentMethods);
  };

  const removeFromForm = () => {
    const newSalonPaymentMethods = formSalon.payment_methods.filter(pm => pm.id !== selectedPaymentMethod.id);
    setFormSalon({
      ...formSalon,
      payment_methods: newSalonPaymentMethods
    });
  };

  const handleWebsiteChange = (e) => {
    setFormSalon({
      ...formSalon,
      website: e.target.value
    })
  }

  const handleCheckboxChange = (e) => {
    setFormSalon({
      ...formSalon,
      [e.target.name]: e.target.checked
    });
  };

  const addToForm = () => {
    const paymentMethodExists = formSalon.payment_methods?.some(pm => pm.id === selectedPaymentMethod.id);
    if (paymentMethodExists) return;

    const newPaymentMethods = [
      ...formSalon.payment_methods,
      selectedPaymentMethod
    ];
    setFormSalon({
      ...formSalon,
      payment_methods: newPaymentMethods
    });
  };

  const removeFromAvailable = () => {
    const newPaymentMethods = paymentMethods.filter(pm => pm.id !== selectedPaymentMethod.id);
    setPaymentMethods(newPaymentMethods);
  };

  const availablePaymentMethods = () => {
    const selectedPaymentMethodIDs = formSalon.payment_methods.map(pm => pm.id);
    return paymentMethods.filter(pm => !selectedPaymentMethodIDs.includes(pm.id));
  };

  const generateSlugPlaceholder = (name) => {
    return name ? name.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-|-$/g, '') : '';
  };

  return (
    <>
      {
        formSalon ? (
          <div className='flex flex-col gap-2 h-full'>
            <div className="flex flex-col gap-2 max-h-[calc(100vh-9rem)] overflow-y-auto">
              <h1 className='text-4xl text-center mb-3'>{t('configurations.business_configuration')}</h1>
              <label className="shrink-0 input input-bordered flex items-center gap-2">
                <FontAwesomeIcon icon="fa-solid fa-house" />
                <input type="text" name="name" className="grow" placeholder={t('configurations.name.placeholder')} value={formSalon.name || ''} onChange={handleFormChange}/>
              </label>
              <label className="shrink-0 input input-bordered flex items-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" /><path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" /></svg>
                <input type="text" name="email" className="grow" placeholder={t('configurations.email.placeholder')} value={formSalon.email || ''} onChange={handleFormChange}/>
              </label>
              <label className="shrink-0 input input-bordered flex items-center gap-2">
                <FontAwesomeIcon icon="fa-brands fa-google" />
                <input type="text" name="google_url" className="grow" placeholder={t('configurations.google_url.placeholder')} value={formSalon.google_url || ''} onChange={handleFormChange}/>
              </label>
              <label className="shrink-0 input input-bordered flex items-center gap-2">
                <FontAwesomeIcon icon="fa-solid fa-map-location-dot" />
                <input type="text" name="address" className="grow" placeholder={t('configurations.address.placeholder')} value={formSalon.address || ''} onChange={handleFormChange}/>
              </label>
              <label className="shrink-0 input input-bordered flex items-center gap-2">
                <FontAwesomeIcon icon="fa-solid fa-globe"/>
                <input type="text" name="website" className="grow" placeholder={t('configurations.website.placeholder')} value={formSalon.website || ''} onChange={handleWebsiteChange}/>
              </label>
              <label className="shrink-0 input input-bordered flex items-center gap-2">
                <FontAwesomeIcon icon="fa-solid fa-globe"/>
                {process.env.REACT_APP_FRONTEND_SERVICE}/
                <input type="text" name="slug" className="grow" placeholder={generateSlugPlaceholder(formSalon.name)} value={formSalon.slug || ''} onChange={handleFormChange}/>
                /citas
              </label>
              <PhoneInput
                placeholder={t('configurations.phone.placeholder')}
                value={formSalon.phone_number || ''}
                onChange={phone => setFormSalon({...formSalon, phone_number: phone})}
                defaultCountry={locale?.country_code}
                className='input input-bordered'
              />
              <label className="shrink-0 input input-bordered flex items-center gap-2">
                <FontAwesomeIcon icon="fa-solid fa-clock" />
                <span className='text-nowrap'>{t('configurations.reschedule_deadline.label')}</span>
                <input type="text" name="reschedule_deadline" className="w-12" placeholder={t('configurations.reschedule_deadline.placeholder')} value={formSalon.reschedule_deadline} onInput={handleDeadlineChange}/>
                <span className='ms-auto'>{t('configurations.reschedule_deadline.units')}</span>
              </label>
              <label className="shrink-0 input input-bordered flex items-center gap-2 cursor-pointer">
                <FontAwesomeIcon icon="fa-solid fa-cart-shopping" className='me-2'/>
                <span className="label-text">{t('configurations.multi_products.label')}</span>
                <input name="multi_products" type="checkbox" className="checkbox checkbox-primary ms-auto" checked={formSalon.multi_products} onChange={handleCheckboxChange}/>
              </label>
              <button className='btn text-center font-bold cursor-pointer join-item' onClick={() => setPaymentsVisible(!paymentsVisible)}>
                { paymentsVisible ? t('configurations.hide_payments') : t('configurations.configure_payments') }
                <FontAwesomeIcon icon="fa-regular fa-credit-card" className='ms-1'/>
                { paymentsVisible ?
                  <FontAwesomeIcon icon="fa-solid fa-chevron-up" className='ms-3'/>
                  : <FontAwesomeIcon icon="fa-solid fa-chevron-down" className='ms-3'/>
                }
              </button>
              { paymentsVisible &&
                <div className='flex flex-col sm:flex-row justify-between gap-2'>
                  <div className="w-full sm:w-1/4 flex flex-col border-2 rounded-lg border-gray-200 overflow-hidden h-32 overflow-y-auto">
                    {
                      availablePaymentMethods().length > 0 ? (
                        availablePaymentMethods()?.map((pm) => {
                          const isSelected = pm === selectedPaymentMethod;
                          return <button key={`payment-method-available-${pm.id}`} className={`btn btn-sm btn-${isSelected ? 'neutral' : 'ghost'} rounded-none w-full`} onClick={() => { setSelectedPaymentMethod(pm) }}>{t(`paymentMethods.${pm.name}`)}</button>
                        })
                      ) : (
                        <div className="text-center my-auto">{t('paymentMethods.all')}</div>
                      )
                    }
                  </div>
                  <div className="hidden sm:flex justify-center sm:flex-col gap-2">
                    <button className='btn' onClick={addPaymentMethod}><FontAwesomeIcon icon="fa-solid fa-angles-right" /></button>
                    <button className='btn' onClick={removePaymentMethod}><FontAwesomeIcon icon="fa-solid fa-angles-left" /></button>
                  </div>
                  <div className="flex sm:hidden justify-center sm:flex-col gap-2">
                    <button className='btn' onClick={addPaymentMethod}><FontAwesomeIcon icon="fa-solid fa-angles-down" /></button>
                    <button className='btn' onClick={removePaymentMethod}><FontAwesomeIcon icon="fa-solid fa-angles-up" /></button>
                  </div>
                  <div className="flex-1 border-2 rounded-lg border-gray-200 overflow-hidden h-32 overflow-y-auto">
                    {
                      formSalon.payment_methods.map((pm) => {
                        const isSelected = pm === selectedPaymentMethod;
                        return <button key={`payment-method-available-${pm.id}`} className={`btn btn-sm btn-${isSelected ? 'neutral' : 'success'} rounded-none w-full`} onClick={() => { setSelectedPaymentMethod(pm) }}>{t(`paymentMethods.${pm.name}`)}</button>
                      })
                    }
                  </div>
                </div>
              }
              <button className='btn text-center font-bold cursor-pointer join-item' onClick={() => setCalendarVisible(!calendarVisible)}>
                { calendarVisible ? t('configurations.hide_schedule') : t('configurations.configure_schedule') }
                <FontAwesomeIcon icon="fa-solid fa-calendar-days" className='ms-1'/>
                { calendarVisible ?
                  <FontAwesomeIcon icon="fa-solid fa-chevron-up" className='ms-3'/>
                  : <FontAwesomeIcon icon="fa-solid fa-chevron-down" className='ms-3'/>
                }
              </button>
              { calendarVisible &&
                <>
                  <label className="shrink-0 input input-bordered flex items-center">
                    <FontAwesomeIcon icon="fa-solid fa-clock" className='me-2'/>
                    {t('configurations.appointments_every')}
                    <CurrencyInput name="slot_interval" placeholder="30 min" onValueChange={handleCurrencyChange} value={formSalon.slot_interval} className="bordered-s grow ms-3" allowNegativeValue={false} allowDecimals={false} disableGroupSeparators={true} />
                    <h1 className="mr-3">{t('configurations.min')}</h1>
                  </label>
                  <div className='flex mt-3 mb-5 ps-3 lg:ps-5'>
                    <button className='btn btn-success h-full me-5 px-1 py-2' >
                      <span style={{ writingMode: 'vertical-rl' }} onClick={() => { handleSlotForm() }}>+ {t('week')}</span>
                    </button>
                    <div id="employee-calendar" className='flex-1 grid grid-rows-4 grid-flow-col lg:grid-rows-1 md:grid-rows-3 gap-4'>
                      {
                        weekDays.map((day, index) => {
                          const schedule = salon.schedule;
                          const slots_for_day = schedule.slots.filter((slot) => slot.week_day === index);
                          return (
                            <div key={index} className='flex-1 flex flex-col gap-1'>
                              <div className="flex items-center gap-2 mb-2">
                                <button className='btn btn-xs btn-success' onClick={() => { handleSlotForm(index, day) }}>+</button>
                                <span className='inline-block align-middle'>{day}</span>
                              </div>
                              <div className='grid lg:grid-cols-1 gap-1'>
                                {
                                  slots_for_day.length > 0 ? (
                                    slots_for_day?.map((slot, index) => {
                                      return (
                                        <div key={index} className='badge badge-outline badge-secondary cursor-pointer' onClick={() => { handleDestroySlot(slot) }}>
                                          {slot.start_time} - {slot.end_time}
                                        </div>
                                      )
                                    })
                                  ) : (
                                    <div className='badge badge-info capitalize'>{t('schedule.free')}</div>
                                  )
                                }
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </>
              }
              <SalonSlotForm />
            </div>
            <button className='btn btn-success mt-auto' onClick={handleFormSubmit} disabled={salon === formSalon}>{t('save')}</button>
          </div>
        ) : (
          <Loading />
        )
      }
    </>
  )
}

export default Configuration;
