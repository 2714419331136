import moment from 'moment';

export function getStartAndEndOfWeek(date) {
  // Start with the given date
  const firstDayOfWeek = 0; // Tuesday
  const givenDate = moment(date);

  // Calculate the start of the week, adjusting so Tuesday is the first day
  let startOfWeek = givenDate.clone().day(firstDayOfWeek);
  let endOfWeek = givenDate.clone().day(firstDayOfWeek + 6); // Calculate end of the week based on start day

  if (givenDate.day() < firstDayOfWeek) {
    startOfWeek = startOfWeek.subtract(1, 'week');
    endOfWeek = endOfWeek.subtract(1, 'week');
  }

  return {
    startOfWeek: startOfWeek.toDate(), // Convert moment object back to JavaScript Date
    endOfWeek: endOfWeek.toDate()      // Convert moment object back to JavaScript Date
  };
}

export function getDatesInRange(startDate, endDate) {
  const dates = [];
  let currentDate = moment(startDate).startOf('day'); // Start from the beginning of the start date in local time
  const endMoment = moment(endDate).startOf('day'); // Go up to the beginning of the end date in local time

  while (currentDate <= endMoment) {
    dates.push(moment(currentDate)); // Add the current date to the array
    currentDate.add(1, 'days'); // Move to the next day
  }

  return dates;
}

export function generateTimeSlots(start = '00:00', end = '23:45') {
  const times = [];
  const startTime = moment(start, 'HH:mm'); // start time
  const endTime = moment(end, 'HH:mm'); // end time

  let currentTime = startTime;

  while (currentTime <= endTime) {
    times.push(currentTime.format('HH:mm'));
    currentTime = currentTime.clone().add(15, 'minutes'); // increment by 15 minutes
  }

  // Add the final time of 23:59 manually
  times.push('23:59');

  return times;
}