import { IntakeProvider } from '../../contexts/IntakeContext';
import IntakeStages from './stages/IntakeStages';

const Intake = ({ reschedule = false }) => {
  return (
    <IntakeProvider>
      <IntakeStages reschedule={reschedule} />
    </IntakeProvider>
  );
};

export default Intake;
