export async function reservationsConfirm(salon, appointment) {
  const url = `${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/reservations/${salon.uuid}/confirm`
  const body = {
    appointment: {
      start_date: appointment.start_date,
      customer_attributes: appointment.customer,
      salon_product_ids: appointment.salon_products.map((product) => product.id),
      payment_method: appointment.payment_method,
      employee_id: appointment.employee?.id
    }
  }
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  })
}

export async function reservationsReschedule(appointment) {
  const url = `${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/reservations/${appointment.uuid}/reschedule`
  const body = {
    appointment: { start_date: appointment.start_date }
  }
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  })
}

export async function reservationsCheckout(salon, appointment) {
  const url = `${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/reservations/${salon.uuid}/checkout`
  const body = {
    appointment: {
      start_date: appointment.start_date,
      customer_attributes: appointment.customer,
      salon_product_ids: appointment.salon_products.map((product) => product.id),
      payment_method: appointment.payment_method,
      employee_id: appointment.employee?.id
    }
  }
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  })
}