import { Outlet } from "react-router-dom";
import Navbar from "../components/navbar";
import Sidebar from "../components/sidebar";
import { AppStateContext } from "../contexts/AppStateContext";
import { useCallback, useContext, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import moment from "moment";

export default function Root() {
  const { sidebarState, salonState, salonsState } = useContext(AppStateContext);
  const [, setSalon,] = salonState;
  const [, setSalons] = salonsState;
  const { nullifyUser } = useAuth();
  const [sidebarOpen, setSidebarOpen] = sidebarState;

  const handleOverlayClick = () => {
    setSidebarOpen(false);
  }

  const nullifySession = useCallback(() => {
    nullifyUser();
    setSalon({})
    setSalons({})
    localStorage.removeItem('salonId')
  },[nullifyUser, setSalon, setSalons])

  useEffect(() => {
    const expires = localStorage.getItem('expires')

    if (expires) {
      const expirationDate = moment.unix(expires)
      const currentDate = moment();
      const hasExpired = currentDate.isAfter(expirationDate);
      if (hasExpired) {
        nullifySession();
      }
    } else {
      nullifySession();
    }
  },[nullifySession])

  return (
    <div className="drawer" style={{ height: 'inherit' }}>
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" checked={sidebarOpen} readOnly/>
      <div className="drawer-content flex flex-col">
        <div className="flex-none">
          <Navbar />
        </div>
        <div className="grow">
          <div className="flex min-h-full">
            <div className="flex-none w-64 lg:block hidden">
              <Sidebar />
            </div>
            <div className="flex-1 max-h-screen overflow-y-auto bg-fixed" style={{
              backgroundImage: "url('office.webp')",
            }}>
              <div className="bg-base-300/95 min-h-full p-2">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="drawer-side z-50">
        <label htmlFor="my-drawer-3" aria-label="close sidebar" className="drawer-overlay" onClick={handleOverlayClick}></label> 
        <Sidebar />
      </div>
    </div>
  );
}