import React, { useContext } from 'react';
import { IntakeContext } from '../../../contexts/IntakeContext';
import moment from 'moment';
import { intlConfig, isValidEmail, isValidPhone } from '../../../components/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { formatValue } from 'react-currency-input-field';

const Cart = ({ reschedule = false }) => {
  const { locale, appointment, selectedSlot, maxDuration, totalPrice, paymentMethod, prepaymentSelected } = useContext(IntakeContext);
  const { t } = useTranslation();

  const formattedPrice = (value) => {
    return formatValue({ value: String(value), intlConfig: intlConfig(locale) })
  }

  return (
    <div className='flex flex-col w-full gap-2'>
      <h2 className="text-2xl font-bold mb-4 mt-3">{t('cart.appointment_summary')}</h2>
      <h3 className="text-lg font-semibold">{appointment.salon_products.length > 0 ? t('cart.selected_products') : t('cart.select_a_product')}</h3>
      {
        appointment.salon_products.length > 0 && <div>
          <div className="divider my-0"></div>
          <ul className="text-end">
            {appointment.salon_products.map((product, index) => (
              <li key={index}>{product.product}</li>
            ))}
          </ul>
          {
            appointment.employee?.id && <div>
              <div>
                <h3 className="text-lg font-semibold">{t('cart.with_professional')}</h3>
                <div className="divider my-0"></div>
                <p className="text-light text-end">{appointment.employee.user.full_name}</p>
              </div>
            </div>
          }
          <div>
            <h3 className="text-lg font-semibold">{t('cart.max_duration')}</h3>
            <div className="divider my-0"></div>
            <p className="text-light text-end">
              { appointment.end_date ? appointment.salon_products.reduce((sum, current) => {
                  return sum + current.duration;
                }, 0) : maxDuration
              } {t('minutes')}</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold">{appointment.paid ? t('paid') : t('intake.stats.total_price.title')}</h3>
            <div className="divider my-0"></div>
            <p className="text-light text-end">{totalPrice === 0 ? t('confirmation.free') : formattedPrice(totalPrice)}</p>
          </div>
        </div>
      }
      { reschedule && <div>
                        <h3 className="text-lg font-semibold">{t('cart.selected_date')}</h3>
                        <div className="divider my-0"></div>
                        <p className="text-light text-end">{moment(appointment.start_date).format('ddd D MMM YYYY - HH:mm')}</p>
                      </div>
      }
      { selectedSlot && <>
          <div>
            <h3 className="text-lg font-semibold">{t('cart.selected_date')}</h3>
            <div className="divider my-0"></div>
            <p className="text-light text-end">{moment(appointment.start_date).format('ddd D MMM YYYY')}</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold">{t('cart.selected_time')}</h3>
            <div className="divider my-0"></div>
            <p className="text-light text-end">{selectedSlot?.label}</p>
          </div>
        </>
      }
      <div>
        { (appointment.customer.first_name || appointment.customer.email || appointment.customer.phone_number) && <>
            <h3 className="text-lg font-semibold">{t('cart.personal_information')}</h3>
            <div className="divider my-0"></div>
          </>
        }
        <ul className=''>
          {appointment.customer.first_name && <li>
            <div className='flex justify-between'>
              <span className='flex-1 text-gray-500'>{t('name')}:</span>
              {appointment.customer.first_name}
            </div>
          </li>}
          {appointment.customer.email && <li>
            <div className='flex justify-between'>
              <span className='flex-1 text-gray-500'>{t('emailShort')}:</span>
              {!reschedule && isValidEmail(appointment.customer.email) && <span className='text-success'>{t('cart.valid')} <FontAwesomeIcon icon="fa-solid fa-check" className='me-2'/></span>}
              {appointment.customer.email}
            </div>
          </li>}
          {appointment.customer.phone_number && <li>
            <div className='flex justify-between'>
              <span className='flex-1 text-gray-500'>{t('phone')}:</span>
              {!reschedule && isValidPhone(appointment.customer.phone_number) && <span className='text-success'>{t('cart.valid')} <FontAwesomeIcon icon="fa-solid fa-check" className='me-2'/></span>}
              {appointment.customer.phone_number}
            </div>
          </li>}
        </ul>
      </div>
      { paymentMethod && <>
          <div>
            <h3 className="text-lg font-semibold">{t('cart.payment_method')}</h3>
            <div className="divider my-0"></div>
            <p className="text-light text-end">{t(`paymentMethods.${paymentMethod.name}`)}</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold">{t('cart.prepayment')}</h3>
            <div className="divider my-0"></div>
            <p className="text-light text-end">{prepaymentSelected ? t('cart.required') : t('cart.not_required')}</p>
          </div>
        </>
      }
    </div>
  );
}

export default Cart;
