import moment from 'moment';
import 'moment/locale/es';
import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { AppStateContext } from '../../contexts/AppStateContext';
import { formatValue } from 'react-currency-input-field';
import { useTranslation } from 'react-i18next';
import I18nSelect from '../../components/utils/i18n_select';
import ThemeSelect from '../../components/utils/theme_select';
import Skeleton from '../../components/utils/skeleton';

const Confirmation = () => {
  let { appointmentUUID, checkoutSessionID } = useParams();
  const [salon, setSalon] = useState({})
  const { localeState } = useContext(AppStateContext);
  const [appointment, setAppointment] = useState({});
  const [locale, setLocale] = localeState;
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAppointment = async () => {
      let url = new URL(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/reservations/${appointmentUUID}`)
      if (checkoutSessionID) {
        url = new URL(`${process.env.REACT_APP_BACKEND_SERVICE}/api/v1/reservations/${appointmentUUID}/confirm_checkout`)
        url.searchParams.append('checkout_session_id', checkoutSessionID);
      }

      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(response => response.json())
        .then(data => {
          if (!data.errors) {
            setSalon(data.salon)
            setLocale(data.salon.locale)
            setAppointment(data.appointment)
          } else {
            toast.error(data.errors.join(','))
          }
          setLoading(false)
        })
        .catch(error => {
          console.error('Error fetching data: ', error);
          setLoading(false)
        });
    }

    fetchAppointment();
  }, [appointmentUUID, setAppointment, checkoutSessionID, setLocale])

  const copyToClipboard = async (e) => {
    try {
      await navigator.clipboard.writeText(e.target.dataset.copy);
      toast.success('copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
      toast.error('Failed to copy');
    }
  };

  const intlConfig = () => {
    return {
      locale: locale?.languages?.split(',')[0],
      currency: locale?.currency
    }
  }

  const showAddress = () => {
    const offlineProducts = appointment.salon_products?.filter((sp) => sp.mode === 'offline')
    return offlineProducts?.length > 0
  }

  const totalToPay = appointment.salon_products?.reduce((acc, product) => acc + product.price_cents, 0);
  const formattedPrice = (value) => {
    return formatValue({ value: String(value / 100.0), intlConfig: intlConfig() })
  }

  const totalDuration = appointment.salon_products?.reduce((acc, product) => acc + product.duration, 0);

  return (
    <div className='container mx-auto p-4 h-full'>
      {
        loading ? <Skeleton /> : (
          <>
            <div className="absolute top-0 end-0">
              <I18nSelect />
            </div>
            <div className='pt-12 flex flex-col gap-3 h-full'>
              <div className='mx-3'>
                <div className="flex items-center justify-between mt-5">
                  <h1 className='text-4xl'>{salon?.name}</h1>
                  <ThemeSelect />
                </div>
                <div className="flex lg:justify-start gap-3 my-3">
                  <a className='btn btn-lg btn-primary' href={`mailto:${salon.email}`}>
                    <FontAwesomeIcon icon="fa-solid fa-envelope" size='2x'/>
                  </a>
                  <a className='btn btn-lg btn-secondary' href={`tel:${salon.phone_number}`}>
                    <FontAwesomeIcon icon="fa-solid fa-phone" size='2x'/>
                  </a>
                  {
                    salon?.address && showAddress() ? <a className='btn btn-lg btn-accent' href={salon.google_url} target='_blank' rel="noreferrer">
                      <FontAwesomeIcon icon="fa-solid fa-map-location-dot" size='2x'/>
                    </a> : null
                  }
                </div>
                <div className='flex flex-col gap-1'>
                  {
                    salon?.email ? <p className='text-xs' onClick={copyToClipboard} data-copy={salon?.email}>
                      <FontAwesomeIcon icon="fa-solid fa-envelope" className='me-3'/>
                      {salon?.email}
                    </p> : null
                  }
                  {
                    salon?.phone_number ? <p className='text-xs' onClick={copyToClipboard} data-copy={salon?.phone_number}>
                      <FontAwesomeIcon icon="fa-solid fa-phone" className='me-3' />
                      {formatPhoneNumberIntl(salon?.phone_number)}
                    </p> : null
                  }
                  {
                    salon?.address && showAddress() ? <p className='text-xs' onClick={copyToClipboard} data-copy={salon?.address}>
                      <FontAwesomeIcon icon="fa-solid fa-map-location-dot" className='me-3' />
                      {salon?.address}
                    </p> : null
                  }
                </div>
              </div>
              <div className="divider"></div>
              <div className='mx-3'>
                <h2 className='text-3xl mb-5 text-light'>{t('confirmation.appointment')} { appointment.status === 'confirmed' ? t('confirmation.confirmed') : t('confirmation.pending') }</h2>

                <div className='flex flex-col'>
                  <div className='flex justify-between'>
                    {t('confirmation.date')}:
                    <strong className='text-light'>{moment(appointment.start_date).format('ddd D MMM YYYY')}</strong>
                  </div>
                  <div className='flex justify-between'>
                    {t('confirmation.time')}:
                    <strong className='text-light'>{moment(appointment.start_date).format('h:mm a')}</strong>
                  </div>
                  <div className='flex justify-between'>
                    {t('confirmation.estimated_duration')}:
                    <strong className='text-light'>{totalDuration} min</strong>
                  </div>
                  {t('confirmation.services')}:
                  <div className="flex justify-end">
                    <table className='w-full lg:w-1/2 text-light'>
                      <tbody>
                        {
                          appointment.salon_products?.map((salon_product) => {
                            return <tr key={salon_product.id}>
                              <td className='text-end'>{salon_product.product}</td>
                              <td className='text-center'>( {salon_product.mode === 'online' ? t('confirmation.video_call') : t('confirmation.in_person')} )</td>
                              <td className='text-end'>{formattedPrice(salon_product.price_cents)}</td>
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="divider"></div>
                  <div className='flex justify-between'>
                    <strong>{ appointment.paid ? t('confirmation.total_paid') : t('confirmation.total_due_at_business')}:</strong>
                    <strong className='text-light'>{totalToPay === 0 ? t('confirmation.free') : formattedPrice(totalToPay)}</strong>
                  </div>
                </div>
              </div>
              <div className='flex flex-col items-center'>
                {t('confirmation.email_sent')}
              </div>
              <div className='flex flex-col items-center'>
                { appointment.google_meet_url && <Link to={appointment.google_meet_url} target='_blank' title='google meet link' className='flex items-center gap-2'>
                  <img src={`${process.env.PUBLIC_URL}/google_meet_logo.png`} width='30' alt=''/> {t('confirmation.link_to_meet')}
                </Link> }
              </div>
              <div className='flex flex-col items-center mt-auto'>
                {t('confirmation.identifier')}:
                <strong className='text-light'>{appointment.uuid}</strong>
              </div>
            </div>
          </>
        )
      }
    </div>
  )
}

export default Confirmation