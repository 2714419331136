import React, { useContext } from 'react'
import { IntakeContext } from '../../../contexts/IntakeContext';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Checkout = () => {
  const { appointment, setAppointment, salon, validPaymentMethods } = useContext(IntakeContext);
  const { t } = useTranslation();

  return (
    <div className="w-full mx-auto my-3 p-3 sm:p-6 bg-light shadow-md rounded-lg">
      <div className="flex gap-2 justify-between">
        <h2 className="text-2xl font-bold mb-6 text-dark">{t('intake.inputs.paymentMethods.title')}</h2>
        <span className='mb-4 mt-1 text-end'><FontAwesomeIcon icon="fa-solid fa-hand-holding-dollar" className='me-2'/>{t('intake.checkout.prepayment_required')}</span>
      </div>
      <div className="grid grid-cols-1 gap-4">
        {
          salon.payment_methods.map((method) => {
            const enabledPaymentMethod = validPaymentMethods?.some(vpm => vpm.id === method.id)
            const isSelected = appointment.payment_method?.id === method.id
            const selectedClass = enabledPaymentMethod && isSelected ? (
                                    "bg-white shadow-md shadow-gray-300 text-gray-800"
                                  ) : (
                                    "border border-gray-100 text-gray-400"
                                  )
            const enabledClass = enabledPaymentMethod ? "" :"text-gray-200 btn-disabled"
            return  <button
                      key={`payment-select-${method.name}`}
                      className={`card cursor-pointer ${selectedClass} ${enabledClass}`}
                      onClick={() => enabledPaymentMethod && setAppointment({ ...appointment, payment_method: method})}
                    >
                      <div className="card-body">
                        <h3 className="card-title text-xl font-semibold">{t(`intake.paymentMethods.${method.name}.title`)}</h3>
                        {
                          enabledPaymentMethod ? (
                            <p className="text-gray-500 text-start">
                              {t(`intake.paymentMethods.${method.name}.body`)}
                            </p>
                          ) : (
                            <p className="text-gray-400 text-start">
                              { t(`intake.paymentMethods.disabled.body`) }
                            </p>
                          )
                        }
                      </div>
                    </button>
          })
        }
      </div>
    </div>
  )
}

export default Checkout