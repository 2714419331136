import moment from 'moment';
import { useContext } from 'react';
import { CalendarContext } from '../../../../contexts/CalendarContext';
import { useTranslation } from 'react-i18next';

const Appointment = ({ appointment, height, over, under, entireDay }) => {
  const { t } = useTranslation();
  const { setSelectedAppointment } = useContext(CalendarContext);

  const startDateMoment = moment(appointment.start_date);
  const endDateMoment = moment(appointment.end_date);

  const startMinutes = startDateMoment.minutes();
  const endMinutes = endDateMoment.minutes();
  const duration = endDateMoment.diff(startDateMoment, 'minutes');

  const top = (startMinutes / 60) * 100;
  const bottom = ((60 - endMinutes) / 60) * 100;

  const body = `${startDateMoment.format('HH:mm')} - ${endDateMoment.format('HH:mm')}`;
  const totalWidth = 90;

  const width = appointment.overlap === undefined ? `${totalWidth}%` : `${totalWidth / appointment.overlap}%`
  const position = appointment.position - 1
  const left = appointment.overlap === undefined ? null : `calc(${(totalWidth / appointment.overlap) * position}%)`

  const appointmentStyle = {
    top: over || entireDay ? null : `${top}%`,
    bottom: over ? `${bottom}%` : null,
    height: (under || over || entireDay) ? height : `${duration}px`,
    width: width,
    left: left,
    zIndex: 1,
    cursor: 'pointer',
    ...appointment.appointment_type === 'default' && appointment.color_style
  }

  const handleAppointmentClick = (event) => {
    event.stopPropagation();

    document.getElementById('appointment-modal').showModal()
    setSelectedAppointment(appointment)
  }

  return (
    <div
      className={`absolute overflow-hidden whitespace-nowrap text-ellipsis left-0 appointment rounded border-2 ${appointment.appointment_type === 'busy' ? 'bg-slate-200' : ''}`}
      style={appointmentStyle}
      onClick={handleAppointmentClick}
      data-id={appointment.uuid}
    >
      <div className='relative w-full h-full'>
        <div className='flex flex-col p-2'>
          <h3 className='truncate capitalize'>
            { appointment.customer ? appointment.customer.full_name : t('busy') }
          </h3>
          <span>{ body }</span>
          <span className='mt-2'>{ appointment.description }</span>
        </div>
        <div className='px-2 text-end w-full bg-slate-300/50 absolute bottom-0 truncate'>{appointment.employee?.user?.first_name}</div>
      </div>
    </div>
  );
};


export default Appointment;