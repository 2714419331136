import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ContactForm from '../../components/contact/form';
import Navbar from '../../components/navbar';
import SignInForm from '../sign_in_form';
import { useAuth } from '../../hooks/useAuth';
import Pricing from './pricing';

const Landing = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col bg-fixed hero" style={{
      backgroundImage: "url('office.webp')",
    }}>
      <Navbar lng={true} landing={true} />
      <main className="w-full mx-auto flex flex-col items-center">
        {
          !isAuthenticated && <section className="hero w-full h-screen bg-base-300/95 bg-fixed">
                                <div className="hero-content flex-col lg:flex-row-reverse">
                                  <div className="text-center lg:text-left sm:ps-10">
                                    <h1 className="text-5xl font-bold">{t('landing.joinNow')}</h1>
                                    <p className="py-6">
                                      {t('landing.intro')}
                                    </p>
                                  </div>
                                  <div className="card bg-base-100 w-full max-w-sm shrink-0 shadow-2xl p-5">
                                    <SignInForm />
                                  </div>
                                </div>
                              </section>
        }
        <section className="w-full h-screen hero bg-base-200 bg-fixed" style={{ backgroundImage: "url('office.webp')" }}>
          <div className="hero-overlay bg-opacity-90"></div>
          <div className="hero-content text-white text-center flex-col lg:flex-row justify-around">
            <img
              className="mask md:mask-parallelogram md:w-3/5 hidden md:inline"
              src="office.webp"
              alt=''
            />
            <div className="flex flex-col h-full gap-3">
              <h1 className="text-5xl font-bold">{t('landing.aboutUs')}</h1>
              <p className="py-6 font-bold text-justify" style={{ 'textShadow': '2px 2px 4px rgba(0, 0, 0, 0.7)' }}>
                {t('landing.aboutUsText')}
              </p>
              <Link to='/conociendonos' className='btn btn-success mt-auto'>{t('landing.know_us')}</Link>
              { isAuthenticated && <Link to='/admin' className='btn btn-info mt-auto'>{t('landing.goToApp')}</Link> }
            </div>
          </div>
        </section>
        <section className="w-full h-screen hero bg-base-100 bg-fixed lg:p-12">
          <div className="hero-content text-center flex-col lg:flex-row xl:w-2/3">
            <div className='mx-auto max-w-4xl p-6 mb-6 text-start'>
              <h2 className="text-2xl font-semibold mb-4">{t('landing.ourServices')}</h2>
              <ul className="list-disc list-inside">
                <li>{t('landing.configureServices')}</li>
                <li>{t('landing.manageSchedules')}</li>
              </ul>
            </div>
            <div className="mockup-phone">
              <div className="camera"></div>
              <div className="display">
                <div className="artboard artboard-demo phone-1 bg-gray-200">
                  <img src="movil_velvet_booking.png" alt="" style={{ position: 'relative', top: 80 }}/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full h-screen hero bg-base-200 bg-fixed lg:p-12">
          <div className="hero-content text-center flex-col lg:flex-row xl:w-2/3">
            <div className="mockup-phone border-info">
              <div className="camera"></div>
              <div className="display">
                <div className="artboard artboard-demo phone-1 bg-base-200">
                  <img src="movil_cita.png" alt="" style={{ position: 'relative', top: 80 }}/>
                </div>
              </div>
            </div>
            <div className='mx-auto max-w-4xl p-6 mb-6 text-start'>
              <ul className="list-disc list-inside">
                <li>{t('landing.appointmentForm')}</li>
                <li>{t('landing.userNotifications')}</li>
              </ul>
            </div>
          </div>
        </section>
        <section className="w-full h-screen bg-base-100">
          <div className="flex flex-col justify-center h-full">
            <h1 className='text-6xl text-center py-12'>{t('pricing.header')}</h1>
            <div className="flex flex-col overflow-x-auto">
              <Pricing />
            </div>
          </div>
        </section>
        <section className="w-full h-screen bg-base-100">
          <div className='mx-auto max-w-4xl p-6 mb-6'>
            <ContactForm />
          </div>
        </section>
      </main>

      <footer className="footer footer-center bg-base-200 text-base-content rounded p-10 mt-auto">
        <nav className="grid grid-flow-col gap-4">
          <a href='/privacy-policy' className="link link-hover">{t('privacyLink')}</a>
          <a href='/terms-and-conditions' className="link link-hover">{t('termsLink')}</a>
        </nav>
        <nav>
          <div className="grid grid-flow-col gap-4">
            <a href='/'>
              <FontAwesomeIcon icon="fa-brands fa-twitter" size='2xl'/>
            </a>
            <a href='/'>
              <FontAwesomeIcon icon="fa-brands fa-instagram" size='2xl'/>
            </a>
            <a href='/'>
              <FontAwesomeIcon icon="fa-brands fa-facebook" size='2xl'/>
            </a>
          </div>
        </nav>
        <aside>
          <p>Copyright © - {new Date().getFullYear()} Velvet Booking. All rights reserved.</p>
        </aside>
      </footer>
    </div>
  );
}

export default Landing;
