import React, { useContext, useEffect, useRef, useState } from 'react';
import ProductSelection from './product_selection';
import DateAndTimeSelection from './date_and_time_selection';
import ContactInformation from './contact_information';
import { IntakeContext } from '../../../contexts/IntakeContext';
import I18nSelect from '../../../components/utils/i18n_select';
import ThemeSelect from '../../../components/utils/theme_select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import Cart from '../cart';
import Checkout from './checkout';
import { validContact, isValidEmail, isValidPhone } from '../../../components/utils';
import { reservationsCheckout, reservationsConfirm, reservationsReschedule } from '../../../lib/api_service/checkout';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const IntakeStages = ({ reschedule = false }) => {
  const { t } = useTranslation();
  const {
    salon, appointment, selectedSlot, selectedDate, confirming, setConfirming,
    totalPrice, validPaymentMethods, acceptedPrivacyAndTerms, setAcceptedPrivacyAndTerms,
    showBusinessInfo, setShowBusinessInfo
  } = useContext(IntakeContext);
  const [stage, setStage] = useState(0);
  const breadcrumbRefs = useRef([]);
  const navigate = useNavigate();

  const stages = reschedule ? [
    { name: 'date_and_time',  component: <DateAndTimeSelection />,        valid: true },
    {
      name: 'reschedule',
      component: <Checkout />,
      valid: !!selectedSlot && !!selectedDate,
      exclude: appointment.paid || totalPrice === 0 || validPaymentMethods.length === 0
    }
  ] : [
    { name: 'product',        component: <ProductSelection />,      valid: true },
    { name: 'date_and_time',  component: <DateAndTimeSelection />,  valid: appointment.salon_products.length > 0 },
    { name: 'contact',        component: <ContactInformation />,    valid: appointment.salon_products.length > 0 && !!selectedSlot && !!selectedDate },
    {
      name: 'checkout',
      component: <Checkout />,
      valid: appointment.salon_products.length > 0 && !!selectedSlot && !!selectedDate && validContact(appointment.customer),
      exclude: totalPrice === 0 || validPaymentMethods.length === 0
    }
  ];
  const includedStages = stages.filter(stage => !stage.exclude )
  const isLastStage = stage + 1 === includedStages.length

  useEffect(() => {
    if (breadcrumbRefs.current[stage]) {
      breadcrumbRefs.current[stage].scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
    }
  }, [stage]);

  const handleStageChange = (newStage) => {
    if (includedStages[newStage].valid) {
      setStage(parseInt(newStage))
      if (showBusinessInfo === true) {
        setShowBusinessInfo(false)
      }
    }
  }

  const handleBusinessInfo = () => {
    setShowBusinessInfo(!showBusinessInfo)
  }

  const copyToClipboard = async (e) => {
    try {
      await navigator.clipboard.writeText(e.target.dataset.copy);
      toast.success(t('intake.clipboard.success'));
    } catch (err) {
      console.error('Failed to copy: ', err);
      toast.error(t('intake.clipboard.error'));
    }
  };

  const validReservation = () => {
    const customer = appointment.customer
    return selectedSlot && isValidEmail(customer?.email) && isValidPhone(customer?.phone_number) && acceptedPrivacyAndTerms
  }

  const handleReservationConfirmation = () => {
    const customer = appointment.customer
    if ((customer.phone_number && !isValidPhone(customer.phone_number))) {
      toast.error(t('intake.validations.phone'))
    }
    if (!customer.email || (customer.email && !isValidEmail(customer.email))) {
      toast.error(t('intake.validations.email'))
    }
    if (!acceptedPrivacyAndTerms) {
      toast.error(t('intake.validations.terms_and_privacy'))
    }
    if (!validReservation()) return

    if (confirming) { return }

    setConfirming(true)

    if (reschedule) {
      rescheduleAppointment();
      return
    }

    if (!appointment.paid && totalPrice !== 0 && appointment.payment_method?.name === 'online') {
      goToCheckout();
    } else {
      confirmAppointmentDirectly();
    }
  }

  const rescheduleAppointment = () => {
    reservationsReschedule(appointment)
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          toast.error(data.errors.join(', '))
          setConfirming(false)
        } else {
          toast.success(t('intake.confirmation.success'))
          setConfirming(false)
          navigate(`/citas/${data.uuid}/confirmacion`);
        }
      })
  }

  const confirmAppointmentDirectly = () => {
    reservationsConfirm(salon, appointment)
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          toast.error(t('intake.confirmation.error'))
          setConfirming(false)
        } else {
          toast.success(t('intake.confirmation.success'))
          setConfirming(false)
          navigate(`/citas/${data.uuid}/confirmacion`);
        }
      })
  }

  const goToCheckout = () => {
    reservationsCheckout(salon, appointment)
      .then((response) => response.json())
      .then((json) => {
        const { url, error } = json;

        if (url) {
          window.location.href = url;
        }

        if (error) {
          toast.error(error)
          setConfirming(false)
        }
      });
  }

  const isPastDeadline = () => {
    const appointmentDate = moment(appointment.start_date);
    const deadlineTime = appointmentDate.subtract(salon.reschedule_deadline, 'hours');
    const now = moment();
    return now.isAfter(deadlineTime);
  }

  return (
    <div className='drawer drawer-end h-full'>
      <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
      <div className="absolute top-0 end-0">
        <I18nSelect />
      </div>
      <div className='drawer-content'>
        <div className='h-full pt-12 p-4 flex justify-between'>
          <div id="stages" className='basis-full sm:basis-2/3 xl:basis-3/4 h-full flex flex-col mx-auto gap-3 max-w-full sm:max-w-3xl sm:pe-4'>
            <div className=''>
              <div className="flex items-center mt-5 gap-1">
                <h1 className='flex-1 text-4xl'>{salon?.name}</h1>
                <ThemeSelect />
                { appointment.salon_products.length > 0 && <label htmlFor="my-drawer-4" className="sm:hidden drawer-button btn btn-ghost p-1">
                    <FontAwesomeIcon icon="fa-solid fa-cart-shopping" size='2xl'/>
                  </label>
                }
              </div>
              <div className="collapse rounded-none">
                <input type="checkbox" checked={showBusinessInfo} readOnly className='hidden'/>
                <div className="collapse-content p-0 overflow-x-auto" style={{paddingBottom: '0px'}}>
                  <div id='config-links' className="flex gap-3 my-5 overflow-x-auto">
                    <a className='btn btn-md btn-accent' href={`mailto:${salon.email}`}>
                      <FontAwesomeIcon icon="fa-solid fa-envelope" size='2x'/>
                    </a>
                    <a className='btn btn-md btn-accent' href={`tel:${salon.phone_number}`}>
                      <FontAwesomeIcon icon="fa-solid fa-phone" size='2x'/>
                    </a>
                    {
                      salon?.google_url ? <a className='btn btn-md btn-accent' href={salon?.google_url} target='_blank' rel="noreferrer">
                        <FontAwesomeIcon icon="fa-solid fa-map-location-dot" size='2x'/>
                      </a> : null
                    }
                    {
                      salon?.website ? <a className='btn btn-md btn-accent' href={salon?.website} target='_blank' rel="noreferrer">
                        <FontAwesomeIcon icon="fa-solid fa-globe" size='2x'/>
                      </a> : null
                    }
                  </div>
                  <div className='flex flex-col gap-1'>
                    {
                      salon?.email ? <p className='text-xs' onClick={copyToClipboard} data-copy={salon?.email}>
                        <FontAwesomeIcon icon="fa-solid fa-envelope" className='me-3'/>
                        {salon?.email}
                      </p> : null
                    }
                    {
                      salon?.phone_number ? <p className='text-xs' onClick={copyToClipboard} data-copy={salon?.phone_number}>
                        <FontAwesomeIcon icon="fa-solid fa-phone" className='me-3' />
                        {formatPhoneNumberIntl(salon?.phone_number)}
                      </p> : null
                    }
                    {
                      salon?.address ? <p className='text-xs' onClick={copyToClipboard} data-copy={salon?.address}>
                        <FontAwesomeIcon icon="fa-solid fa-map-location-dot" className='me-3' />
                        {salon?.address}
                      </p> : null
                    }
                    {
                      salon?.website ? <p className='text-xs' onClick={copyToClipboard} data-copy={salon?.website}>
                        <FontAwesomeIcon icon="fa-solid fa-globe" className='me-3' />
                        {salon?.website}
                      </p> : null
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="divider cursor-pointer" onClick={handleBusinessInfo}>
              <div className="divider-text">
                { showBusinessInfo ? <FontAwesomeIcon icon="fa-solid fa-circle-chevron-up" /> : <FontAwesomeIcon icon="fa-solid fa-info-circle" size='xl' className='text-accent'/> }
              </div>
            </div>
            {
              reschedule && isPastDeadline() ? (
                <div className='h1 text-center'>
                  <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" className='text-warning me-2'/>
                  {t('intake.reschedule.deadline_passed', { deadline: salon.reschedule_deadline })}
                </div>
              ) : (
                <>
                  <div className="text-lg breadcrumbs">
                    <ul>
                      {
                        Object.entries(includedStages).map(([key, { name, valid }]) => {
                          return (
                            valid && <li key={key}>
                                {
                                  stage === parseInt(key) ? (
                                    <div className='font-bold' ref={(el) => (breadcrumbRefs.current[key] = el)}>{t(`intake.stages.${name}`)}</div>
                                  ) : (
                                    <button className='link link-hover text-neutral-400' onClick={() => handleStageChange(key)} ref={(el) => (breadcrumbRefs.current[key] = el)}>{t(`intake.stages.${name}`)}</button>
                                  )
                                }
                                {
                                  includedStages[parseInt(key) + 1]?.valid ? <FontAwesomeIcon icon='fa-solid fa-check' className='ms-2 text-success'/> : null
                                }
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                  <div className="grow flex flex-col gap-5">{includedStages[stage].component}</div>
                  {
                    isLastStage ? (
                      <>
                        {
                          confirming ? (
                            <span className="loading loading-lg loading-spinner text-warning mx-auto"></span>
                          ) : (
                            <>
                              <div className="mt-auto flex">
                                <label className="label cursor-pointer">
                                  <input
                                    type="checkbox"
                                    checked={acceptedPrivacyAndTerms}
                                    onChange={(e) => setAcceptedPrivacyAndTerms(e.target.checked)}
                                    className="checkbox checkbox-primary me-2"
                                  />
                                  <span className="label-text">
                                    {`${t('intake.terms_and_privacy.accept')}`}
                                    <a href="/terms-and-conditions" className="text-blue-500 underline mx-1" target="_blank" rel="noopener noreferrer">
                                      {t('intake.terms_and_privacy.privacy')}
                                    </a>
                                    { t('and') }
                                    <a href="/terms-and-conditions" className="text-blue-500 underline mx-1" target="_blank" rel="noopener noreferrer">
                                      {t('intake.terms_and_privacy.terms')}
                                    </a>
                                  </span>
                                </label>
                              </div>
                              <button className='btn btn-primary w-full capitalize' disabled={confirming || !validReservation()} onClick={handleReservationConfirmation}>
                                {
                                  reschedule ? t(`reschedule`) : (
                                    appointment.payment_method ? t(`intake.paymentMethods.${appointment.payment_method.name}.pay`) : t('intake.confirm')
                                  )
                                }
                              </button>
                            </>
                          )
                        }
                      </>
                    ) : (
                      <button className='btn btn-success' disabled={!stages[stage + 1]?.valid} onClick={() => handleStageChange(stage + 1)}>{t('intake.continue')}</button>
                    )
                  }
                </>
              )
            }
          </div>
          <div id="cart" className='flex-none basis-0 sm:basis-1/3 xl:basis-1/4 hidden sm:flex ps-4 border-s-2 border-gray-50'>
            <Cart reschedule={reschedule}/>
          </div>
        </div>
      </div>
      <div className="drawer-side">
        <label htmlFor="my-drawer-4" aria-label="close sidebar" className="drawer-overlay"></label>
        <div className="bg-base-200 text-base-content min-h-full w-full sm:w-1/4 p-4">
          <label htmlFor="my-drawer-4" className="drawer-button btn btn-ghost btn-sm p-0">
            <FontAwesomeIcon icon="fa-regular fa-circle-xmark" size='2xl'/>
          </label>
          <Cart reschedule={reschedule}/>
        </div>
      </div>
    </div>
  );
};

export default IntakeStages;
