import React from 'react'
import { useAuth } from '../../hooks/useAuth';

const AdminLanding = () => {
  const { isAuthenticated, user } = useAuth();

  return (
    <div className='container h-full'>
      <div className="flex flex-col">
        {
          isAuthenticated && user ? (
            <>
              <h1 className='text-5xl font-bold'>Bienvenido <strong className='text-5xl italic text-primary'>{user.full_name}</strong> a tu sistema de reserva de citas</h1>
              <p className='py-6 font-bold text-justify'>
                Administre su negocio de manera eficiente
              </p>
            </>
          ) : (
            <>
              <h1 className='text-5xl font-bold italic'>Bienvenido a nuestro sistema</h1>
              <h1 className='text-5xl font-bold italic'>de reserva de citas</h1>
              <p className='py-6 font-bold text-justify'>
                Por favor iniciar sesión para continuar
              </p>
            </>
          )
        }
      </div>
    </div>
  )
}

export default AdminLanding