import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { AppStateContext } from "../../../contexts/AppStateContext";
import { accountLink, getAccount } from "../../../lib/api_service/stripe";
import { useTranslation } from 'react-i18next';
import Skeleton from "../../../components/utils/skeleton";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Status() {
  const { salonState } = useContext(AppStateContext)
  const [salon,,] = salonState
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(false)
  const [account, setAccount] = useState({})
  const [accountLinkCreatePending, setAccountLinkCreatePending] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!salon) return

    if (salon.online_payments && salon.stripe_account_id) {
      setFetching(true)
      getAccount(salon)
        .then(response => response.json())
        .then((data) => {
          if (data.errors) {
            toast.error(data.errors)
          } else {
            setAccount(data)
          }
          setFetching(false)
        })
    }
  },[salon, navigate])

  const yesOrNo = (result) => {
    return result === true || result === 'active' ? (
      <span className="text-success font-bold">{ t('yes') }</span>
    ) : (
      <span className="text-error font-bold">{ t('stripe.requires_more_information') }</span>
    )
  }

  return (
    <>
      {
        fetching ? <Skeleton />
        : <div className="flex flex-col items-center bg-base-100/50 p-3">
            <div className="flex items-center justify-between w-full">
              <h2 className="text-xl font-bold">{ t('stripe.account_status') }</h2>
              { account.payouts_enabled && account.charges_enabled ? <Link to={'https://dashboard.stripe.com/test/dashboard'} className="btn btn-primary">
                  {t('stripe.go_to')} <FontAwesomeIcon icon="fa-brands fa-stripe" size='2xl'/>
                </Link> : <button
                    className='btn btn-primary min-w-44'
                    onClick={async () => {
                      setAccountLinkCreatePending(true);
                      accountLink(salon)
                        .then((response) => response.json())
                        .then((json) => {
                          const { url, error } = json;
                          if (url) {
                            window.location.href = url;
                          }

                          if (error) {
                            toast.error(t('toast.error'))
                            console.error(error)
                          }
                        });
                    }}
                  >
                    { accountLinkCreatePending ? <span className="loading loading-spinner text-warning"></span> : t('stripe.add_information') }
                  </button>
              }
            </div>
            <table className="table rounded-none">
              <thead>
                <tr>
                  <th>{ t('stripe.features') }</th>
                  <th>{ t('stripe.status') }</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{ t('stripe.capabilities.payouts') }</td>
                  <td>{ yesOrNo(account.payouts_enabled) }</td>
                </tr>
                <tr>
                  <td>{ t('stripe.capabilities.charges') }</td>
                  <td>{ yesOrNo(account.charges_enabled) }</td>
                </tr>
                {
                  account?.capabilities && Object.entries(account?.capabilities).map(([key, value]) => {
                    return (
                      <tr key={key}>
                        <td>{ t(`stripe.capabilities.${key}`) }</td>
                        <td>{ yesOrNo(value) }</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
      }
    </>
  );
}
