import React from 'react'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./error-page";
import SignIn from './routes/sign_in';
import Products from './routes/admin/products';
import Configuration from './routes/admin/configuration';
import Landing from './routes/landing';
import AuthenticatedRoute from './routes/admin/authenticated_route';
import Root from './routes/root';
import Appointments from './routes/admin/appointments';
import QrCode from './routes/admin/qr_code';
import Employees from './routes/admin/employees';
import SwitchBusiness from './routes/admin/switch_business';
import ConfirmCancellation from './routes/cancellations/confirm';
import ConfirmedCancellation from './routes/cancellations/confirmed';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Confirmation from './routes/reservations/confirmation';
import PasswordResetForm from './routes/users/password_reset_form';
import PasswordResetRequestForm from './routes/users/password_reset_request_form';
import Profile from './routes/admin/profile';
import AdminLanding from './routes/admin/admin_landing';
import './i18n';
import PrivacyPolicy from './routes/privacy_policy';
import TermsAndConditions from './routes/terms_and_conditions';
import Refresh from './routes/admin/stripe/refresh';
import CheckoutCancellation from './routes/reservations/checkout_cancellation';
import StripeDashboard from './routes/admin/stripe/dashboard';
import ProductForm from './components/products/form';
import Intake from './routes/reservations/intake';
import EmployeeCalendar from './components/employees/calendar';
import ContactForm from './components/contact/form';

library.add(fas, fab, far);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Landing />
  },
  {
    path: '/contacto_recibido',
    element: <Landing />
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />
  },
  {
    path: '/terms-and-conditions',
    element: <TermsAndConditions />
  },
  {
    path: '/admin',
    element: <Root />, // This layout includes the Navbar
    children: [
      { index: true, element: <AdminLanding /> },
      { path: "contact", element: <ContactForm redirect={false} /> },
      {
        path: "sign_in",
        element: <SignIn />,
      },
      {
        path: "password/reset/:token",
        element: <PasswordResetForm />
      },
      {
        path: "password/reset",
        element: <PasswordResetRequestForm />
      },
      {
        path: "dashboard",
        element: <AuthenticatedRoute />,
        children: [
          { index: true, element: <Appointments /> },
          { path: "products", element: <Products /> },
          { path: "products/new", element: <ProductForm /> },
          { path: "products/:salonProductID", element: <ProductForm /> },
          { path: "reservations_qr", element: <QrCode /> },
          { path: "employees", element: <Employees /> },
          { path: "employees/:employeeId", element: <EmployeeCalendar /> },
          { path: "configuration", element: <Configuration /> },
          { path: "switch", element: <SwitchBusiness /> },
          { path: "profile", element: <Profile /> },
          { path: "stripe/refresh/:connectedAccountId", element: <Refresh /> },
          { path: "stripe", element: <StripeDashboard /> }
        ],
        errorElement: <ErrorPage />
      }
    ]
  },
  {
    path: "/:salonUUID/citas",
    element: <Intake />
  },
  {
    path: "/conociendonos",
    element: <Intake />
  },
  {
    path: "/citas/:appointmentUUID/reprogramar",
    element: <Intake reschedule={true} />
  },
  {
    path: "/citas/:appointmentUUID/confirmacion",
    element: <Confirmation />
  },
  {
    path: "/citas/:appointmentUUID/pago_confirmado/:checkoutSessionID",
    element: <Confirmation />
  },
  {
    path: "/citas/:appointmentUUID/pago_cancelado/:checkoutSessionID",
    element: <CheckoutCancellation />
  },
  {
    path: "/cancelar/:appointmentUUID",
    element: <ConfirmCancellation />
  },
  {
    path: "/reprogramar/:appointmentUUID",
    element: <Intake reschedule={true} />
  },
  {
    path: "/cancelacion-exitosa",
    element: <ConfirmedCancellation />
  }
]);

const App = () => {
  return (
    <>
      <ToastContainer position="top-center" theme="dark"/>
      <RouterProvider router={router} />
    </>
  )
}

export default App
