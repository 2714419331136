import React from 'react'
import { useTranslation } from 'react-i18next';

const I18nSelect = ({ direction = 'bottom', align = 'end' }) => {
  const languages = [{ code: 'en-US', label: 'ENG' }, { code: 'es-ES', label: 'ESP' }]
  const { i18n } = useTranslation();
  const notSelected = () => languages.filter(({code}) => code !== i18n.language)

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className={`dropdown dropdown-${direction} dropdown-${align} z-50`}>
      <div tabIndex={0} role="button" className="btn btn-ghost text-[12px]">{languages.find(({code}) => code === i18n.language)?.label || 'ESP'}</div>
      <ul tabIndex={0} className="dropdown-content menu rounded-box z-[1] w-32 p-2 shadow">
        {
          notSelected().map(({code, label}) => {
            return <li key={code}><button onClick={() => changeLanguage(code)} className="btn btn-sm btn-success"><span className='text-default'>{label}</span></button></li>
          })
        }
      </ul>
    </div>

  )
}

export default I18nSelect