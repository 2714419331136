import React, { useContext } from 'react'
import { IntakeContext } from '../../../contexts/IntakeContext';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Select from 'react-select';
import Datepicker, { registerLocale } from 'react-datepicker';
import { CustomSingleValue, CustomSlotOption } from '../../../components/utils/select';
import { es } from 'date-fns/locale';

registerLocale('es-ES', es)

const DateAndTimeSelection = () => {
  const {
    capacity, setAppointment,
    selectedDate, setSelectedDate,
    selectedSlot, setSelectedSlot,
    maxDuration, currentMonthConfigRef,
    freeSlots
  } = useContext(IntakeContext)
  const { t, i18n } = useTranslation();

  const handleValueChange = (newValue) => {
    if (newValue !== selectedDate) {
      setSelectedSlot(null);
      setSelectedDate(newValue);
    }
  }

  const handleSlotChange = (slot) => {
    setSelectedSlot(slot)
    setAppointment((prev) => {
      return {
        ...prev,
        start_date: slot.value.local().format()
      }
    })
  }

  const handleMonthChange = async (date) => {
    const today = moment().startOf('day'); // Get today's date with time set to 00:00:00
    const selectedDate = moment(date).startOf('day'); // Ensure the time part of the date is also set to 00:00:00

    if (selectedDate.isSameOrAfter(today)) {
      setSelectedDate(date);
    } else {
      setSelectedDate(new Date());
    }
  }

  const renderDayContents = (day, date) => {
    const colorClass = dayColor(day, date);
    const fontWeightClass = dayFontWeight(day, date);
    return (
      <span
        className={`${colorClass} ${fontWeightClass}`}
      >
        {colorClass === 'loading' ? <span className="loading loading-spinner loading-xs"></span> : day}
      </span>
    );
  };

  const dayFontWeight = (day, date) => {
    const isSameMonth = moment(date).isSame(selectedDate, 'month');
    return isSameMonth ? 'font-bold' : 'font-normal';
  };

  const dayColor = (day, date) => {
    const month = moment(date).month();
    const isBeforeToday = moment(date).isBefore(moment(), 'day');
    const isSameMonth = month === moment(selectedDate).month();

    if (!capacity) return 'error';
    if (isBeforeToday || !isSameMonth) return 'neutral';

    const currentConfig = currentMonthConfigRef.current;
    if (!capacity?.[`${currentConfig}`]) {
      return 'loading'
    }

    const daysWithCapacity = capacity?.[`${currentConfig}`]?.days_with_capacity || [];

    return daysWithCapacity.includes(day) ? 'day-enabled' : 'day-disabled';
  };

  return (
    <>
      <Datepicker
        selected={selectedDate || new Date()}
        onChange={handleValueChange}
        dateFormat="ddd - MMM DD, YYYY"
        onMonthChange={handleMonthChange}
        minDate={new Date()}
        renderDayContents={renderDayContents}
        locale={i18n.language}
        customInput={
          <label className="input shadow-md shadow-gray-200 bg-gray-50 text-black flex items-center mb-3">
            <input
              type="text"
              className="grow"
              placeholder={t('intake.inputs.date.placeholder')}
              value={selectedDate ? moment(selectedDate).format('ddd - MMM DD, YYYY') : ''}
              readOnly
            />
          </label>
        }
        inline
      />
      {
        selectedDate && (
          <>
            <h2>{t('intake.inputs.slots.title')}</h2>
            <Select
              placeholder={t('intake.inputs.slots.placeholder')}
              isSearchable={false}
              value={selectedSlot}
              onChange={handleSlotChange}
              components={{ Option: CustomSlotOption, SingleValue: CustomSingleValue }}
              noOptionsMessage={() => t('intake.inputs.slots.no_options')}
              styles={{
                control: () => {},
              }}
              classNames={{
                control: () =>
                    'input shadow-md bg-gray-50 text-black shadow-gray-200 flex items-center',
              }}
              options={freeSlots?.map((slot) => {
                const { time, salon_products } = slot
                let online = salon_products?.filter((sp) => sp.mode === 'online')[0]?.mode
                const slotMoment = moment.parseZone(time)
                return {
                  value: slotMoment,
                  label: `${slotMoment.format('H:mm')} - ${slotMoment.clone().add(maxDuration, 'minutes').format('H:mm')}`,
                  mode: online
                }
              }) || []}
            />
          </>
        )
      }
    </>
  )
}

export default DateAndTimeSelection