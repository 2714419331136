import React, { useContext } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { AppStateContext } from '../../contexts/AppStateContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const QrCode = () => {
  const { salonState } = useContext(AppStateContext);
  const [salon,,] = salonState;
  const salonUrl = `${process.env.REACT_APP_FRONTEND_SERVICE}/${salon?.slug || salon?.uuid}/citas`;
  const { t } = useTranslation();

  const handlePrint = () => {
    const qrCodeToPrint = document.getElementById('qrCodeToPrint').innerHTML;
    const printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>');
    printWindow.document.write(t('qr_code.print_title'));
    printWindow.document.write('</title></head><body>');
    printWindow.document.write(qrCodeToPrint);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(salonUrl);
      toast.success(t('qr_code.copy_success'));
    } catch (err) {
      console.error('Failed to copy: ', err);
      toast.error(t('qr_code.copy_error'));
    }
  };

  return (
    <div className='flex flex-col p-3 rounded-lg items-center gap-3 m-auto bg-base-100/50' style={{ marginTop: '8rem', width: '360px' }}>
      <h1 className='w-full text-center'>{t('qr_code.title')}</h1>
      <div className="flex justify-end w-full gap-5">
        <a href={salonUrl} target='_blank' rel="noreferrer"><FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" /></a>
        <button onClick={copyToClipboard}><FontAwesomeIcon icon="fa-solid fa-copy" /></button>
        <button onClick={handlePrint}><FontAwesomeIcon icon="fa-solid fa-print" /></button>
      </div>
      <div id='qrCodeToPrint' style={{ position: 'relative', display: 'inline-block', backgroundColor: '#f8f8f8', padding: '10px' }}>
        <QRCodeSVG
          value={salonUrl}
          size={300}
          bgColor="#f8f8f8"
          fgColor="#3A3A3A"
          level="Q"
        />
      </div>
    </div>
  );
}

export default QrCode;
