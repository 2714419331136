import { useContext } from "react";
import { AppStateContext } from "../../contexts/AppStateContext";
import { deleteProduct } from "../../lib/api_service/products";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from "react-toastify";
import { formatValue } from 'react-currency-input-field';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Products = () => {
  const { salonState, localeState, employeesState, salonProductsState, productFormState } = useContext(AppStateContext);
  const [locale,] = localeState;
  const [salon,,] = salonState;
  const [employees,] = employeesState;
  const [salonProducts, setSalonProducts] = salonProductsState;
  const [, setEditingProduct,] = productFormState;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCreate = () => {
    setEditingProduct({
      name: '',
      price: 0,
      duration: 0,
      blocks: true,
      parallel_limit: 1,
      description: '',
      mode: 'offline'
    });
    navigate('new');
  };

  const handleEdit = (salonProduct) => {
    setEditingProduct(
      {
        id: salonProduct.id,
        name: salonProduct.product,
        price: salonProduct.price_cents / 100.0,
        duration: salonProduct.duration,
        blocks: salonProduct.blocks,
        parallel_limit: salonProduct.parallel_limit,
        description: salonProduct.description,
        mode: salonProduct.mode
      }
    );
    navigate(`${salonProduct.id}`);
  };

  const handleDestroy = (salonProduct) => {
    const userConfirmed = window.confirm(t('products.delete.confirmation', { name: salonProduct.product }));
    if (!userConfirmed) return;

    deleteProduct(salon, salonProduct)
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          toast.error(t('products.delete.error', { name: salonProduct.product }));
        } else {
          let newProducts = { ...salonProducts };
          delete newProducts[salonProduct.id];
          setSalonProducts(newProducts);
          toast.success(t('products.delete.success', { name: salonProduct.product }));
        }
      });
  };

  const intlConfig = {
    locale: locale?.languages?.split(',')[0],
    currency: locale?.currency
  };

  return (
    <>
      <button className='w-full btn btn-success mb-2' onClick={handleCreate}>{t('products.create.new')}</button>
      <div className="overflow-x-auto max-h-[calc(100vh-9rem)] pb-12 overflow-y-auto">
        <table className="table table-pin-rows bg-base-100/60">
          <thead>
            <tr>
              <th className="min-w-12">{t('products.details.product')}</th>
              <th>{t('products.details.price')} <FontAwesomeIcon icon="fa-solid fa-coins" /></th>
              <th>{t('products.details.duration')} <FontAwesomeIcon icon="fa-solid fa-clock" /></th>
              <th>{t('products.details.capacity')} <FontAwesomeIcon icon="fa-solid fa-users" /></th>
              <th>{t('products.details.mode')}</th>
              <th>{t('products.details.features')}</th>
              <th>{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.values(salonProducts)?.length > 0 && Object.values(salonProducts).map((salonProduct) => {
                const formattedPrice = formatValue({ value: String(salonProduct.price_cents / 100.0), intlConfig: intlConfig });
                const employeesCapable = Object.values(employees)?.filter((emp) => emp.salon_products?.map(sp => sp.id).includes(salonProduct.id));
                const capacityColor = employeesCapable.length === 0 ? 'text-error' : 'text-success';
                return (
                  <tr key={salonProduct.id}>
                    <td>{ salonProduct.product }</td>
                    <td>{formattedPrice}</td>
                    <td>{salonProduct.duration} min</td>
                    <td className={`font-bold ${capacityColor}`}>{ employeesCapable.length }</td>
                    <td>
                      {
                        salonProduct.mode === 'offline' ?  <>
                          <FontAwesomeIcon icon="fa-solid fa-door-open" /> {t('products.details.mode_offline')}
                        </> : <>
                          <FontAwesomeIcon icon="fa-solid fa-video" /> {t('products.details.mode_online')}
                        </>
                      }
                    </td>
                    <td>
                      <div className="self-start flex gap-2">
                        { salonProduct.online_payments && <div className="tooltip tooltip-right" data-tip={t('products.details.online_payments')}><FontAwesomeIcon icon="fa-regular fa-credit-card" className="text-light" /></div> }
                        { salonProduct.prepayment_required && <div className="tooltip tooltip-right" data-tip={t('products.details.prepayment_required')}><FontAwesomeIcon icon="fa-solid fa-hand-holding-dollar" /></div> }
                        { !salonProduct.blocks && <div className="tooltip tooltip-right" data-tip={t('products.details.no_blocks')}><FontAwesomeIcon icon="fa-solid fa-hand" /></div> }
                      </div>
                    </td>
                    <td>
                      <div className="flex gap-2">
                        <button className="btn btn-outline border-none" onClick={() => { handleEdit(salonProduct); }}>
                          <FontAwesomeIcon icon="fa-solid fa-edit" />
                        </button>
                        <button className="btn btn-outline btn-error border-none" onClick={() => { handleDestroy(salonProduct); }}>
                          <FontAwesomeIcon icon="fa-solid fa-trash" />
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Products;
