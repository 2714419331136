import { createContext, useCallback, useContext, useState } from "react";
import { delayAppointment, deleteAppointment } from "../lib/api_service/appointments";
import { useTranslation } from "react-i18next";
import { AppStateContext } from "./AppStateContext";
import { toast } from "react-toastify";
import moment from "moment";

export const CalendarContext = createContext();

const defaultAppointment = {
  start_date: '',
  end_date: '',
  customer: {
    user: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
    }
  },
  salon_products: [],
  paid: false,
  payment_method: {},
  appointment_type: 'default',
  description: ''
};

export const CalendarContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const [appointments, setAppointments] = useState({});
  const saveAppointment = (updatedAppointment) => {
    const newAppointment = {
      ...updatedAppointment,
      start_date: new Date(updatedAppointment.start_date),
      end_date: new Date(updatedAppointment.end_date),
      color_style: getRandomLightColor()
    }
    setAppointments(prev => ({ ...prev, [updatedAppointment?.id]: newAppointment }));
  };
  const [formAppointment, setFormAppointment] = useState(defaultAppointment)

  const resetForm = (attributes) => {
    setFormAppointment({
      ...defaultAppointment,
      ...attributes,
      employee: selectedEmployee || {}
    })
  }
  const [fetchedDates, setFetchedDates] = useState({});
  const { salonState } = useContext(AppStateContext)
  const [salon,] = salonState
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTab, setSelectedTab] = useState('list');
  const [selectedEmployee, setSelectedEmployee] = useState({});

  const handleNextWeek = useCallback((mod, firstDayOfWeek = 0) => {
    let selectedMoment = moment(selectedDate);
    let selectedTime = selectedMoment.hour();

    const newWeek = selectedTab === 'list' ? (
      selectedMoment.add(1, 'days').toDate()
    ) : (
      selectedMoment.add(7, 'days').hour(selectedTime).toDate()
    );

    setSelectedDate(newWeek);
  }, [selectedDate, selectedTab]);

  const handleLastWeek = useCallback((mod, firstDayOfWeek = 0) => {
    let selectedMoment = moment(selectedDate);
    let selectedTime = selectedMoment.hour();

    const newWeek = selectedTab === 'list' ? (
      selectedMoment.subtract(1, 'days').toDate()
    ) : (
      selectedMoment.subtract(7, 'days').hour(selectedTime).toDate()
    );

    setSelectedDate(newWeek);
  }, [selectedDate, selectedTab]);

  const getRandomLightColor = useCallback(() => {
    const base = 200;
    const darkenFactor = 20;
    const red = base + Math.floor(Math.random() * (255 - base));
    const green = base + Math.floor(Math.random() * (255 - base));
    const blue = base + Math.floor(Math.random() * (255 - base));
    const darkerRed = Math.max(0, red - darkenFactor);
    const darkerGreen = Math.max(0, green - darkenFactor);
    const darkerBlue = Math.max(0, blue - darkenFactor);

    return {
      backgroundColor: `rgb(${red}, ${green}, ${blue}, 1)`,
      borderColor: `rgb(${darkerRed}, ${darkerGreen}, ${darkerBlue}, 1)`,
    };
  }, []);

  const handleAppointmentDestroy = () => {
    const userConfirmed = window.confirm(t('appointments.delete.confirmation'));
    if (!userConfirmed) return;

    deleteAppointment(salon, selectedAppointment)
      .then(response => response.json())
      .then((data) => {
        if (data.errors) {
          toast.error(t('appointments.delete.error'));
        } else {
          delete appointments[selectedAppointment.id];
          setAppointments({ ...appointments });
          document.getElementById('appointment-modal').close();
          toast.success(t('appointments.delete.success'));
        }
      });
  };

  const handleDelay = () => {
    const userConfirmed = window.confirm(t('appointments.delay_confirmation'));
    if (!userConfirmed) return;

    delayAppointment(salon, selectedAppointment)
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            toast.success(data.message);
          });
        } else {
          response.json().then((data) => {
            toast.error(data.message);
          });
        }
      }).catch((error) => {
        console.error(error);
      });
  };

  const generalStates = {
    appointments, setAppointments, defaultAppointment,
    saveAppointment, formAppointment, setFormAppointment,
    fetchedDates, setFetchedDates,
    selectedAppointment, setSelectedAppointment,
    selectedTimeSlot, setSelectedTimeSlot,
    selectedDate, setSelectedDate,
    selectedTab, setSelectedTab,
    selectedEmployee, setSelectedEmployee
  }

  const functions = {
    handleNextWeek, handleLastWeek,
    getRandomLightColor,
    resetForm,
    handleAppointmentDestroy, handleDelay
  }

  return (
    <CalendarContext.Provider
      value={{
        ...generalStates,
        ...functions
      }}
    >
      { children }
    </CalendarContext.Provider>
  )
}
