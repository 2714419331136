import React, { useContext, useEffect, useState } from 'react';
import { AppStateContext } from '../../contexts/AppStateContext';
import { createProduct, updateProduct } from '../../lib/api_service/products';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const animatedComponents = makeAnimated();

const ProductForm = () => {
  const { t } = useTranslation();
  const defaultProduct = {
    product: '',
    description: '',
    price: 0,
    price_currency: 'EUR',
    duration: 0,
    blocks: true,
    parallel_limit: 1,
    mode: 'offline',
    prepayment_required: false,
    payment_methods: [{ id: 1, name: 'cash' }]
  };
  const { salonState, salonProductsState, productFormState, localeState } = useContext(AppStateContext);
  const [salonProducts, setSalonProducts] = salonProductsState;
  const [locale,] = localeState;
  const [salon,,] = salonState;
  const [,, saveProduct] = productFormState;
  const navigate = useNavigate();
  const { salonProductID } = useParams();
  const [formProduct, setFormProduct] = useState(defaultProduct);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const handleChange = (e) => {
    setFormProduct({
      ...formProduct,
      [e.target.name]: e.target.value
    });
  };

  const handleCheckboxChange = (e) => {
    setFormProduct({
      ...formProduct,
      [e.target.name]: e.target.checked
    });
  };

  const handleModeChange = (e) => {
    setFormProduct({
      ...formProduct,
      [e.target.name]: e.target.checked ? 'online' : 'offline'
    });
  };

  const handleCurrencyChange = (value, name) => {
    setFormProduct({
      ...formProduct,
      [name]: value
    });
  };

  useEffect(() => {
    if (salon) {
      setPaymentMethods(salon.payment_methods);
    }
  }, [salon]);

  useEffect(() => {
    const editingProduct = salonProducts[salonProductID];

    if (salonProducts && editingProduct?.id) {
      setFormProduct({
        ...editingProduct,
        price: editingProduct.price_cents / 100.0
      });
    }
  }, [salonProducts, salonProductID]);

  const validateForm = () => {
    if (formProduct.product.length > 0) {
      return { valid: true };
    } else {
      return { valid: false, message: t('products.validation.name_required') };
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { valid, message } = validateForm();
    if (!valid) {
      toast.warning(message);
      return;
    }

    if (formProduct?.id) {
      updateProduct(salon, formProduct)
        .then(response => response.json())
        .then(data => {
          if (!data.errors) {
            saveProduct(data);
            toast.success(t('products.update.success'));
            navigate(`/admin/dashboard/products`);
          } else {
            toast.error(t('products.update.error'));
          }
        });
    } else {
      createProduct(salon, formProduct)
        .then(response => response.json())
        .then(data => {
          if (!data.errors) {
            setSalonProducts(prev => ({ ...prev, [data.id]: data }));
            toast.success(t('products.create.success'));
            navigate(`/admin/dashboard/products`);
          } else {
            toast.error(t('products.create.error', { errors: JSON.stringify(data.errors) }));
          }
        });
    }
  };

  const intlConfig = {
    locale: locale?.languages?.split(',')[0],
    currency: locale?.currency
  };

  const availablePaymentMethods = () => {
    const selectedPaymentMethodIDs = formProduct.payment_methods?.map(pm => pm.id);
    return paymentMethods.filter(pm => !selectedPaymentMethodIDs?.includes(pm.id));
  };

  const handlePaymentMethodChange = (selectedValues) => {
    const paymentMethodsSelected = salon.payment_methods.filter((pm) => {
      return selectedValues?.some((selectedValue) => {
        return pm.id === selectedValue.value;
      });
    });
    setFormProduct({
      ...formProduct,
      payment_methods: paymentMethodsSelected
    });
  };

  const hasOnlinePayments = () => {
    return formProduct.payment_methods.some(pm => pm.name === 'online');
  };

  return (
    <div id="product-form" className="container pt-3">
      <div className="flex flex-col gap-3 overflow-visible">
        <label className="input input-bordered flex items-center">
          <h1 className='shrink-0 w-24 me-3'>{t('products.form.product')}</h1>
          <input name="product" type="text" placeholder={t('products.form.placeholders.product')} value={formProduct.product} onChange={handleChange} className="w-full" required={true} />
        </label>
        <label className="input input-bordered flex items-center">
          <h1 className='shrink-0 w-24 me-3'>{t('products.form.price')}</h1>
          <CurrencyInput intlConfig={intlConfig} name="price" placeholder={t('products.form.placeholders.price')} value={formProduct.price} onValueChange={handleCurrencyChange} className="w-full" decimalsLimit={2} />
        </label>
        <label className="input input-bordered flex items-center">
          <h1 className='shrink-0 w-24 me-3'>{t('products.form.duration')}</h1>
          <CurrencyInput name="duration" placeholder={t('products.form.placeholders.duration')} onValueChange={handleCurrencyChange} value={formProduct.duration} className="w-full" allowNegativeValue={false} allowDecimals={false} disableGroupSeparators={true} />
          <h1 className="mr-3">min</h1>
        </label>
        <label className="input input-bordered flex items-center">
          <h1 className='shrink-0 w-24 me-3'>{t('products.form.parallelism')}</h1>
          <input name="parallel_limit" type="number" min={1} step={1} placeholder={t('products.form.placeholders.parallelism')} value={formProduct.parallel_limit} onChange={handleChange} className="w-full" />
        </label>
        <label className="textarea textarea-bordered flex items-center">
          <h1 className='shrink-0 w-24 me-3'>{t('products.form.description')}</h1>
          <textarea name="description" placeholder={t('products.form.placeholders.description')} value={formProduct.description} onChange={handleChange} className="textarea textarea-bordered w-full" required={true} />
        </label>
        <div className="flex flex-col sm:flex-row items-start sm:items-center w-full gap-1 sm:gap-4">
          <h1 className='shrink-0'>{t('products.form.labels.payment_methods')}</h1>
          <Select
            placeholder={t('products.form.placeholders.payment_methods')}
            noOptionsMessage={() => { return t('paymentMethods.all') }}
            isMulti
            isSearchable={false}
            value={formProduct.payment_methods?.map((pm) => {
              return { value: pm.id, label: t(`paymentMethods.${pm.name}`) }
            })}
            onChange={handlePaymentMethodChange}
            components={animatedComponents}
            options={availablePaymentMethods()?.map((pm) => {
              return { value: pm.id, label: t(`paymentMethods.${pm.name}`) }
            })}
            styles={{
              multiValue: () => {},
              control: () => {},
            }}
            classNames={{
              multiValue: () => 'badge badge-info',
              valueContainer: () => 'flex gap-x-2',
              container: () => 'grow w-full',
              control: () =>
                  'input input-bordered flex items-center h-fit',
            }}
          />
        </div>
        <div className="form-control">
          <label className="label cursor-pointer">
            <FontAwesomeIcon icon="fa-solid fa-hand" className='me-2'/>
            <span className="label-text">{t('products.form.blocks')}</span>
            <input name="blocks" type="checkbox" className="checkbox checkbox-primary ms-auto" checked={formProduct.blocks} onChange={handleCheckboxChange}/>
          </label>
        </div>
        <div className="form-control">
          <label className="label cursor-pointer">
            <FontAwesomeIcon icon="fa-solid fa-video" className='me-2'/>
            <span className="label-text">{t('products.form.video_call')}</span>
            <input name="mode" type="checkbox" className="checkbox checkbox-success ms-auto" checked={formProduct.mode === 'online'} onChange={handleModeChange}/>
          </label>
        </div>
        {
          hasOnlinePayments() ? <div className="form-control">
            <label className="label cursor-pointer">
              <FontAwesomeIcon icon="fa-solid fa-hand-holding-dollar" className='me-2'/>
              <span className="label-text">{t('products.form.prepayment_required')}</span>
              <input name="prepayment_required" type="checkbox" className="checkbox checkbox-success ms-auto" checked={formProduct.prepayment_required} onChange={handleCheckboxChange}/>
            </label>
          </div> : null
        }
        <button className="btn btn-success" onClick={handleSubmit} >{t('save')}</button>
        <button className='btn btn-neutral w-full' onClick={() => { navigate(`/admin/dashboard/products`) }}>
          {t('cancel')}
        </button>
      </div>
    </div>
  );
}

export default ProductForm;
